import React, { useCallback } from "react";
import Accordion from "../../../../../../components/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { setAllSelected } from "../../../../../../features/data/dataSlice";

const ValidationsList = () => {
  const { allSelected } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const titleCallBack = useCallback(
    (e) => {
      dispatch(setAllSelected(e.target.checked));
    },
    [dispatch]
  );

  return (
    <Accordion
      allActive={allSelected}
      onSelectAll={titleCallBack}
      allTitle="Select All Tests"
    />
  );
};

export default ValidationsList;
