import React from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { Button, Select, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch, useSelector } from "react-redux";
import { downloadFileAsText } from "../../utils/helpers";
import { setSelectedFilterType } from "../../features/data/dataSlice";

const InfoCard = () => {
  const dispatch = useDispatch();
  const { runedTestStatistic, runnedTestAnswers, selectedFilterType } =
    useSelector((state) => state.data);
  let filteredRunnedTestAnswers = [...runnedTestAnswers];

  if (selectedFilterType !== 1) {
    filteredRunnedTestAnswers = runnedTestAnswers.filter((answer) =>
      selectedFilterType === 2 ? !!answer.status : !answer.status
    );
  }

  return (
    <Wrapper>
      <ChartInfoWrapper>
        <StyledChart
          lineWidth={40}
          data={[
            {
              title: "One",
              value: runedTestStatistic.error,
              color: COLORS.error,
            },
            {
              title: "Two",
              value: runedTestStatistic.passed,
              color: COLORS.success,
            },
          ]}
        />
        <div>
          <TextWrapper type={"default"}>
            Result ({runedTestStatistic.passed + runedTestStatistic.error})
          </TextWrapper>
          <TextWrapper type={"success"}>
            Passed Test - {runedTestStatistic.passed}
          </TextWrapper>
          <TextWrapper type={"error"}>
            Failed Test - {runedTestStatistic.error}
          </TextWrapper>
        </div>
      </ChartInfoWrapper>
      <Space>
        <Select
          placeholder="Select Result type"
          style={{ width: 200 }}
          defaultValue={selectedFilterType}
          onChange={(e) => dispatch(setSelectedFilterType(e))}
        >
          <Select.Option value={1}>All</Select.Option>
          <Select.Option value={2}>Passed</Select.Option>
          <Select.Option value={3}>Failed</Select.Option>
        </Select>

        <Button
          onClick={() =>
            downloadFileAsText(JSON.stringify(filteredRunnedTestAnswers), "file")
          }
        >
          <UploadOutlined />
          Export
        </Button>
      </Space>
    </Wrapper>
  );
};

export default InfoCard;

const TextWrapper = styled.header`
  padding: 0 16px;
  padding-bottom: 8px;
  color: ${(props) => COLORS[props.type]};
  font-size: 16px;
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: flex;
  border: 1px solid ${COLORS.grey_2};
  background-color: ${COLORS.grey_3};
  min-height: 80px;
  border-radius: 12px;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
`;

const StyledChart = styled(PieChart)`
  width: 76px;
`;

const ChartInfoWrapper = styled.div`
  display: flex;
  padding: 24px 0;
`;
