import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from "antd";
import styled from "styled-components";
import Logo from "../../utils/icons/relum_icon.png";
import { post } from "../../client";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils/colors";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = COLORS["grey_3"];

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const onSubmit = async (params) => {
    setLoading(true);

    try {
      const res = await post("Authentication/AuthenticateUser", params);
      localStorage.setItem("token", res.response.token);

      if (res.response.token) {
        navigate("/validator");
      }
    } catch (err) {
      console.log(err, 999);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Wrapper>
        <StyledCard bordered={false}>
          <LogoWrapper>
            <img
              width={120}
              style={{ textAlign: "center" }}
              height={37.5}
              src={Logo}
              alt={"Logo"}
            />
          </LogoWrapper>
          <div>
            <InfoSection>
              <div>Welcome back</div>
              <div>Please enter your credentials.</div>
            </InfoSection>
            <div>
              <Form
                layout="vertical"
                name="basic"
                onFinish={onSubmit}
                onFinishFailed={() => console.log("finish fail")}
                autoComplete="off"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <WarningText>
            *If you have any trouble while login, please contact your
            administrator.
          </WarningText>
        </StyledCard>
      </Wrapper>
      <WarningText>Copyright {new Date().getFullYear()} Relum. All Rights Reserved</WarningText>
    </div>
  );
};

export default Login;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 480px;
  margin: 0 auto;
  height: 90vh;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  width: 100%;
  .ant-card-body {
    padding: 48px 40px 40px;
  }

  border-radius: 16px;
`;

const InfoSection = styled.div`
  margin-bottom: 40px;
  font-size: 14px;
  color: ${COLORS["grey"]};

  :first-child {
    color: ${COLORS["black"]};
    font-size: 20px;
    font-weight: 700;
  }
`;

const LogoWrapper = styled.div`
  text-align: center;
  padding-bottom: 40px;
`;

const WarningText = styled.div`
  font-size: 12px;
  color: ${COLORS["grey"]};
  text-align: center;
`;
