export const ArrowUpIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.41962 6.10122L9.99066 12.4636L16.5979 6.10122C17.4692 5.24152 18.9578 6.65147 18.05 7.51138L10.6076 14.7679C10.2809 15.0774 9.73632 15.0774 9.4459 14.7679L1.93096 7.51138C1.05971 6.65168 2.54804 5.24147 3.41951 6.10122H3.41962Z"
      fill="#68686B"
    />
  </svg>
);

export const ArrowDownIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5804 13.8988L10.0093 7.53645L3.40206 13.8988C2.5308 14.7585 1.0422 13.3485 1.94997 12.4886L9.39243 5.2321C9.7191 4.92263 10.2637 4.92263 10.5541 5.2321L18.069 12.4886C18.9403 13.3483 17.452 14.7585 16.5805 13.8988L16.5804 13.8988Z"
      fill="#68686B"
    />
  </svg>
);

export const ErrorIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
      fill="#E35656"
    />
  </svg>
);

export const SuccessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
      fill="#68C56C"
    />
  </svg>
);

export const CopyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4 22.75H7.6C3.21 22.75 1.25 20.79 1.25 16.4V12.6C1.25 8.21 3.21 6.25 7.6 6.25H10.6C11.01 6.25 11.35 6.59 11.35 7C11.35 7.41 11.01 7.75 10.6 7.75H7.6C4.02 7.75 2.75 9.02 2.75 12.6V16.4C2.75 19.98 4.02 21.25 7.6 21.25H11.4C14.98 21.25 16.25 19.98 16.25 16.4V13.4C16.25 12.99 16.59 12.65 17 12.65C17.41 12.65 17.75 12.99 17.75 13.4V16.4C17.75 20.79 15.79 22.75 11.4 22.75Z"
      fill="#68686B"
    />
    <path
      d="M17 14.15H13.8C10.99 14.15 9.84998 13.01 9.84998 10.2V6.99999C9.84998 6.69999 10.03 6.41999 10.31 6.30999C10.59 6.18999 10.91 6.25999 11.13 6.46999L17.53 12.87C17.74 13.08 17.81 13.41 17.69 13.69C17.58 13.97 17.3 14.15 17 14.15ZM11.35 8.80999V10.2C11.35 12.19 11.81 12.65 13.8 12.65H15.19L11.35 8.80999Z"
      fill="#68686B"
    />
    <path
      d="M15.6 2.75H11.6C11.19 2.75 10.85 2.41 10.85 2C10.85 1.59 11.19 1.25 11.6 1.25H15.6C16.01 1.25 16.35 1.59 16.35 2C16.35 2.41 16.01 2.75 15.6 2.75Z"
      fill="#68686B"
    />
    <path
      d="M7 5.75C6.59 5.75 6.25 5.41 6.25 5C6.25 2.93 7.93 1.25 10 1.25H12.62C13.03 1.25 13.37 1.59 13.37 2C13.37 2.41 13.03 2.75 12.62 2.75H10C8.76 2.75 7.75 3.76 7.75 5C7.75 5.41 7.41 5.75 7 5.75Z"
      fill="#68686B"
    />
    <path
      d="M19.1899 17.75C18.7799 17.75 18.4399 17.41 18.4399 17C18.4399 16.59 18.7799 16.25 19.1899 16.25C20.3299 16.25 21.2499 15.32 21.2499 14.19V8C21.2499 7.59 21.5899 7.25 21.9999 7.25C22.4099 7.25 22.7499 7.59 22.7499 8V14.19C22.7499 16.15 21.1499 17.75 19.1899 17.75Z"
      fill="#68686B"
    />
    <path
      d="M22 8.74999H19C16.34 8.74999 15.25 7.65999 15.25 4.99999V1.99999C15.25 1.69999 15.43 1.41999 15.71 1.30999C15.99 1.18999 16.31 1.25999 16.53 1.46999L22.53 7.46999C22.74 7.67999 22.81 8.00999 22.69 8.28999C22.58 8.56999 22.3 8.74999 22 8.74999ZM16.75 3.80999V4.99999C16.75 6.82999 17.17 7.24999 19 7.24999H20.19L16.75 3.80999Z"
      fill="#68686B"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99996 1.16663C3.78579 1.16663 1.16663 3.78579 1.16663 6.99996C1.16663 10.2141 3.78579 12.8333 6.99996 12.8333C10.2141 12.8333 12.8333 10.2141 12.8333 6.99996C12.8333 3.78579 10.2141 1.16663 6.99996 1.16663ZM9.53746 9.08246C9.45579 9.22246 9.30996 9.29829 9.15829 9.29829C9.08246 9.29829 9.00663 9.28079 8.93663 9.23413L7.12829 8.15496C6.67913 7.88663 6.34663 7.29746 6.34663 6.77829V4.38663C6.34663 4.14746 6.54496 3.94913 6.78413 3.94913C7.02329 3.94913 7.22163 4.14746 7.22163 4.38663V6.77829C7.22163 6.98829 7.39663 7.29746 7.57746 7.40246L9.38579 8.48163C9.59579 8.60413 9.66579 8.87246 9.53746 9.08246Z"
      fill="#A9A9AA"
    />
  </svg>
);
