import React from "react";
import { Col, Divider, Flex, Row, Typography } from "antd";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { camelCaseToTitleCase } from "../../utils/helpers";
import { ClockIcon, CopyIcon } from "../../utils/icons";

const { Paragraph } = Typography;

const CopyText = ({ children }) => {
  return (
    <Paragraph
      style={{ marginBottom: 0 }}
      copyable={{
        icon: (
          <CopyIconWrapper>
            <CopyIcon />
          </CopyIconWrapper>
        ),
      }}
    >
      {children}
    </Paragraph>
  );
};

const DetailedResponse = ({ data }) => {
  const {
    requestData,
    responseData,
    elapsedTime,
    apiName,
    requestUrl,
    requestHeaders,
  } = data;

  return (
    <Row gutter={10}>
      <Divider>{camelCaseToTitleCase(apiName)}</Divider>

      {requestUrl && (
        <Col sm={24} style={{ marginBottom: 16 }}>
          <TitleWrapper>
            <span>Request URL</span>
          </TitleWrapper>

          <InfoWrapper>
            <CopyText>{requestUrl}</CopyText>
          </InfoWrapper>
        </Col>
      )}

      {requestHeaders && (
        <Col sm={24} style={{ marginBottom: 16 }}>
          <TitleWrapper>
            <span>Request Headers</span>
          </TitleWrapper>

          <InfoWrapper>{requestHeaders}</InfoWrapper>
        </Col>
      )}

      <Col span={responseData ? 12 : 24}>
        <TitleWrapper>
          <span>Request Data</span>
        </TitleWrapper>
        <InfoWrapper>
          <CopyText>{requestData?.split(",")?.join(",\n")}</CopyText>
        </InfoWrapper>
      </Col>

      {responseData && (
        <Col span={12}>
          <TitleWrapper>
            <span>Response Data</span>
            <Flex align="center" gap={2}>
              <ClockIcon /> <span>{elapsedTime}</span>
            </Flex>
          </TitleWrapper>

          <InfoWrapper>
            <CopyText>{responseData?.split(",")?.join(",\n")}</CopyText>
          </InfoWrapper>
        </Col>
      )}
    </Row>
  );
};

export default DetailedResponse;

const InfoWrapper = styled.div`
  border: 1px solid ${COLORS.grey_2};
  background-color: ${COLORS.grey_3};
  word-break: break-word;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: ${COLORS.default};
  line-height: 20px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const TitleWrapper = styled.div`
  justify-content: space-between;
  display: flex;

  :first-child {
    font-weight: 600;
    font-size: 14px !important;
  }

  span {
    font-weight: 400;
    font-size: 11px;
  }
`;

const CopyIconWrapper = styled.div`
  align-self: flex-end;

  svg {
    width: 14px;
    height: 14px;
  }

  path {
    fill: #4060e5;
  }
`;
