import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Segment from "../../../../components/Segment";
import { COLORS } from "../../../../utils/colors";
import { useDispatch } from "react-redux";
import { getTestCases } from "../../../../features/data/dataSlice";

const TypeSelector = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(1);

  useEffect(() => {
    dispatch(getTestCases(1));
  }, [dispatch]);

  const validationTypes = [
    {
      name: "Functional Tests",
      id: 1,
    },
    // {
    //   name: "Operation Types",
    //   id: 2,
    //   disabled: true,
    // },
    // {
    //   name: "Check Crypto",
    //   id: 3,
    //   disabled: true,
    // },
  ];

  return (
    <Wrapper>
      <Segment
        types={validationTypes}
        callBack={({ id }) => {
          dispatch(getTestCases(id));
          setSelected(id);
        }}
        selected={selected}
      />
    </Wrapper>
  );
};

export default TypeSelector;

const Wrapper = styled.header`
  border-bottom: 1px solid ${COLORS.neutral};
  padding-left: 32px;
`;
