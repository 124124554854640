import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import TypeSelector from "./__partials/TypeSelector";
import MainViewValidator from "./__partials/MainViewValidator";

const MainValidator = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <Header />
      <TypeSelector />
      <MainViewValidator />
    </>
  );
};

export default MainValidator;
