import { message } from "antd";

async function sendRequest(url, method = "GET", headers = {}, data = null) {
  try {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: data ? JSON.stringify(data) : null,
    };

    const response = await fetch(url, options);
    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return responseData;
  } catch (error) {
    console.error("Request error:", error.message);
    throw error;
  }
}

// Example usage:

// GET request
export const get = async (endpoint, query) => {
  try {
    const queryString = "?" + new URLSearchParams(query).toString();
    const result = await sendRequest(
      `${process.env.REACT_APP_API}${endpoint}${queryString}`
    );

    return result;
  } catch (error) {
    console.error(error);
    // Handle errors
  }
};

// POST request
export const post = async (endpoint, body) => {
  try {
    const result = await sendRequest(
      `${process.env.REACT_APP_API}${endpoint}`,
      "POST",
      {},
      body
    );

    if (result.errorCode) {
      throw new Error(result.errorMessage);
    }

    return result;
  } catch (error) {
    message.error(error.message);
  }
};

export const authPost = async (endpoint, body) => {
  try {
    const result = await sendRequest(
      `${process.env.REACT_APP_API}${endpoint}`,
      "POST",
      {
        Authorization: localStorage.getItem("token"),
      },
      body
    );
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const authGet = async (endpoint, query) => {
  try {
    const queryString = query
      ? "?" + new URLSearchParams(query).toString()
      : "";
    const result = await sendRequest(
      `${process.env.REACT_APP_API}${endpoint}${queryString}`,
      "GET",
      {
        Authorization: localStorage.getItem("token"),
      }
    );

    return result;
  } catch (error) {
    console.error(error);
    // Handle errors
  }
};
