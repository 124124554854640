import React from "react";
import Logo from "../../utils/icons/relum_icon.png";
import LogOutIcon from "../../utils/icons/logout.png";
import { Button, Flex, Segmented } from "antd";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSimpleTesting } from "../../features/data/dataSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <HeaderWrapper>
      <img width={120} height={37.5} src={Logo} alt={"Logo"} />
      <Switcher>
        <Segmented
          onChange={(e) => dispatch(setSimpleTesting(e === "Simple Test"))}
          size={"large"}
          options={["Simple Test", "Full Test"]}
        />
      </Switcher>
      <Button type="text" onClick={() => onLogOut()}>
        <Flex align="center" gap={4}>
          <img width={16} height={16} src={LogOutIcon} alt="LogOut" />
          Log Out
        </Flex>
      </Button>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid ${COLORS.neutral};
`;

const Switcher = styled.div`
  color: ${COLORS.black};
  .ant-segmented-item-label {
    font-size: 14px !important;
  }
`;
