import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { Divider, Flex } from "antd";
import DetailedResponse from "../DetailedResponse";
import { camelCaseToTitleCase } from "../../utils/helpers";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ErrorIcon,
  SuccessIcon,
} from "../../utils/icons";

const ResponseCard = ({ type, data }) => {
  const [open, setOpen] = useState(false);
  const { caseName, message } = data;
  const hasApiData = !!data?.apiData?.[0];

  return (
    <Wrapper type={type}>
      <Flex justify="space-between" align="center">
        <ContentWrapper>
          <Flex align="start" gap={8}>
            <TypeIcon>
              {type === "success" ? <SuccessIcon /> : <ErrorIcon />}
            </TypeIcon>
            <Flex vertical gap={2}>
              <Title>{camelCaseToTitleCase(caseName)}</Title>
              {type === "success" ? "Success" : message}
            </Flex>
          </Flex>
        </ContentWrapper>

        {hasApiData && (
          <IconWrapper onClick={() => setOpen(!open)}>
            {open ? <ArrowDownIcon /> : <ArrowUpIcon />}
          </IconWrapper>
        )}
      </Flex>

      {open && <Divider />}

      {open &&
        hasApiData &&
        data.apiData.map((data) => <DetailedResponse data={data} />)}
    </Wrapper>
  );
};

export default ResponseCard;

const Wrapper = styled.div`
  justify-content: space-between;
  border: 1px solid ${({ type }) => COLORS[type]};
  border-radius: 12px;
  align-items: center;
  padding: 16px 16px;
`;

const ContentWrapper = styled.div``;

const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const TypeIcon = styled.div`
  flex: 0 0 auto;
`;
