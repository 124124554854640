import React from "react";
import Response from "./__partials/Response";
import ValidationForm from "./__partials/ValidationForm";
import ValidationsList from "./__partials/ValidationsList";
import styled from "styled-components";
import { Row, Col } from "antd";
import { COLORS } from "../../../../utils/colors";
import { useSelector } from "react-redux";

const MainViewValidator = () => {
  const { runedTestStatistic } = useSelector((state) => state.data);

  return (
    <Wrapper>
      <Item span={6}>
        <ValidationsList />
      </Item>
      <Item span={6}>
        <ValidationForm />
      </Item>
      <Item span={12}>
        {!!(runedTestStatistic.passed + runedTestStatistic.error) && (
          <Response />
        )}
      </Item>
    </Wrapper>
  );
};

export default MainViewValidator;

const Wrapper = styled(Row)`
  height: calc(100vh - 137px);
  padding-top: 32px;
  padding-left: 24px;
  overflow: hidden;
`;

const Item = styled(Col)`
  height: 100%;
  overflow: auto;
  padding: 0 24px 24px;

  &:nth-child(2) {
    border-right: 1px solid ${COLORS.neutral};
    border-left: 1px solid ${COLORS.neutral};
  }
`;
