import React from "react";
import { Divider } from "antd";
import ResponseCard from "../ResponseCard";
import { useSelector } from "react-redux";
import { camelCaseToTitleCase } from "../../utils/helpers";

const ResponseWrapper = () => {
  const { runnedTestAnswers, selectedFilterType } = useSelector((state) => state.data);
  let filteredRunnedTestAnswers = [...runnedTestAnswers];

  if(selectedFilterType !== 1) {
    filteredRunnedTestAnswers = runnedTestAnswers.filter(answer => selectedFilterType === 2 ? !!answer.status : !answer.status)
  }
  
  return (
    <>
      {filteredRunnedTestAnswers.map((answer) => (
        <div key={answer.actionCase}>
          <Divider>{camelCaseToTitleCase(answer.title)}</Divider>

          <ResponseCard
            data={answer}
            type={answer.status ? "success" : "error"}
          />
        </div>
      ))}
    </>
  );
};

export default ResponseWrapper;
