import { Radio } from "antd";
import {
  CONTENT_TYPE_JSON,
  CONTENT_TYPE_MESSAGE_PACK,
  contentTypeEnum,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setContentType } from "../../features/data/dataSlice";
import styled from "styled-components";

const ContentType = () => {
  const { contentType } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(setContentType(e.target.value));
  };

  return (
    <StyledRadioGroup onChange={onChange} value={contentType}>
      <StyledRadio value={CONTENT_TYPE_JSON}>
        {contentTypeEnum[CONTENT_TYPE_JSON]}
      </StyledRadio>
      <StyledRadio value={CONTENT_TYPE_MESSAGE_PACK}>
        {contentTypeEnum[CONTENT_TYPE_MESSAGE_PACK]}
      </StyledRadio>
    </StyledRadioGroup>
  );
};

export default ContentType;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  border: 1px solid #dedede;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const StyledRadio = styled(Radio)`
  padding: 12px 16px;
  margin-right: 0;

  &:first-child {
    border-bottom: 1px solid #dedede;
  }
`;
