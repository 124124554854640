export const camelCaseToTitleCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, function (match) {
      return match.toUpperCase();
    });
};

export const downloadFileAsText = (content) => {
  const link = document.createElement("a");
  const file = new Blob([content], { type: "text/plain" });
  link.href = URL.createObjectURL(file);
  link.download = "result.txt";
  link.click();
  URL.revokeObjectURL(link.href);
};
