import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../../utils/colors";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const Checkbox = ({ defaultValue = false, callBack }) => {
  const [active, setActive] = useState(defaultValue);

  return (
    <Wrapper
      active={active}
      onClick={() => {
        if (callBack) {
          callBack();
        }
        setActive((prevState) => !prevState);
      }}
    >
      <IconWrapper active={active}>
        {active ? <MinusOutlined /> : <PlusOutlined />}
      </IconWrapper>
    </Wrapper>
  );
};

export default Checkbox;

const Wrapper = styled.div`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.active ? COLORS.primary : COLORS.grey_2)};
  align-items: center;
  justify-content: center;
  display: flex;
`;

const IconWrapper = styled.div`
  span {
    padding-left: 0 !important;
    font-size: 13px;
    color: ${(props) => (props.active ? COLORS.primary : COLORS.grey)};
  }
`;

// const Checked = styled.div`
//   width: 10px;
//   height: 10px;
//   background-color: ${COLORS.primary};
//   border-radius: 2px;
//   display: block;
// `;
