import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import MainValidator from "./pages/MainValidator";
import Login from "./pages/Login";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/validator" element={<MainValidator />} />
      </Routes>
    </Provider>
  );
}

export default App;
