import React from "react";
import InfoCard from "../../../../../../components/InfoCard";
import ResponseWrapper from "../../../../../../components/ResponseWrapper";

const Response = () => {
  return (
    <>
      <InfoCard />
      <ResponseWrapper />
    </>
  );
};

export default Response;
