import React, { useState } from "react";
import { COLORS } from "../../../../utils/colors";
import styled from "styled-components";
import Plus from "../../../CheckBox/Plus";
import { useDispatch } from "react-redux";
import {
  selectSection,
  selectTestCase,
} from "../../../../features/data/dataSlice";
import { camelCaseToTitleCase } from "../../../../utils/helpers";
import { Checkbox } from "antd";

const Item = ({ data }) => {
  const { SectionName, TestCaseDetails, selected } = data;
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <ItemWrapper title>
        <div>
          <Plus callBack={() => setExpanded((prevState) => !prevState)} />
          <span>{camelCaseToTitleCase(SectionName)}</span>
        </div>
        <Checkbox
          checked={selected}
          onChange={() => dispatch(selectSection(SectionName))}
        />
      </ItemWrapper>

      {expanded &&
        TestCaseDetails.map((item) => (
          <ItemWrapper key={item.CaseId}>
            <div>
              <span>{camelCaseToTitleCase(item.DisplayedName)}</span>
            </div>
            <Checkbox
              checked={item.selected}
              onChange={() => {
                dispatch(
                  selectTestCase({
                    sectionName: SectionName,
                    caseId: item.CaseId,
                  })
                );
              }}
            />
          </ItemWrapper>
        ))}
    </Wrapper>
  );
};

export default Item;

const Wrapper = styled.div`
  background-color: ${COLORS.grey_3};
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral};
  margin-top: 5px;
`;

const ItemWrapper = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${COLORS.neutral};
  padding: 16px 0;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => (props.title ? 600 : 400)};
  margin-left: ${(props) => (props.title ? 0 : "25px")};
  margin-right: ${(props) => (props.title ? 0 : "20px")};
  padding-right: ${(props) => (props.title ? "20px" : 0)};

  &:first-child {
    div {
      display: flex;
      span {
        padding-left: 8px;
      }
    }
  }
`;
