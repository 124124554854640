export const COLORS = {
    grey: '#68686B',
    grey_2: '#DEDEDE',
    grey_3: '#F8F8F8',
    black: '#28282C',
    neutral: '#F0F0F0',
    primary: '#4060E5',
    white: '#FFFFFF',

    //text type colors
    success: '#68C56C',
    error: '#E35656',
    default: '#28282C'
}