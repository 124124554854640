import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types"; // Import PropTypes
import { COLORS } from "../../utils/colors";

const Segment = ({ types, callBack, selected }) => (
  <Wrapper>
    {types?.map((type) => (
      <SegmentWrapper
        active={selected === type.id ? 1 : 0}
        onClick={() => callBack(type)}
        key={type.id}
      >
        <SegmentItem>{type.name}</SegmentItem>
      </SegmentWrapper>
    ))}
  </Wrapper>
);

Segment.propTypes = {
  types: PropTypes.array.isRequired, // Validate 'types' as an array
  callBack: PropTypes.func, // Validate 'callBack' as a function
  selected: PropTypes.number, // Validate 'defaultActive' as a string
};

export default Segment;

const SegmentWrapper = styled.div`
  border-bottom: 2px solid
    ${(props) => (props.active === 1 ? COLORS.primary : "none")};
  padding: 16px 0;
  cursor: pointer;
  &:last-child {
    span {
      border-right: none;
      padding-right: 0;
    }
  }

  &:first-child {
    span {
      padding-left: 0;
    }
  }
`;

const SegmentItem = styled.span`
  border-right: 1px solid ${COLORS.neutral};
  padding: 0 12px;
  color: ${COLORS.grey};
  font-weight: 600;
  font-size: 14px;
`;

const Wrapper = styled.div`
  display: flex;
`;
